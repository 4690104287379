import { Paper } from "@material-ui/core";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { getToken } from "../../utils/getToken";
import { useAusmassDigitalLink } from "../../utils/useAusmassDigitalLink";

export const BillsNew: React.FC<RouteComponentProps> = () => {
  const ausmassDigitalLink = useAusmassDigitalLink();
  if (ausmassDigitalLink === "") return null;

  return (
    <Paper style={{ padding: 20 }}>
      <iframe title="Neue Rechnungstabellen" src={`${ausmassDigitalLink + `/rechnung?layout=no-layout&momoAuth=${getToken()}`}`}
        style={{ width: '100%', height: '100vh', border: 'none', background: '#fafafa' }} />
    </Paper>
  )
}
