import { Paper } from "@material-ui/core";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { getToken } from "../../utils/getToken";
import { useAusmassDigitalLink } from "../../utils/useAusmassDigitalLink";

export const AusmassNeu: React.FC<RouteComponentProps> = () => {
  const ausmassDigitalLink = useAusmassDigitalLink();
  if (ausmassDigitalLink === "") return null;

  return (
    <Paper style={{ padding: 20 }}>
      <iframe title="Einsätze Neu" src={`${ausmassDigitalLink + `/ausmass?layout=no-layout&momoAuth=${getToken()}`}`}
        style={{ width: '100%', height: '100vh', border: 'none', background: '#fafafa' }} />
    </Paper>
  )
}
