import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Layout from '../components/Page/Layout';
import { default as RestrictedRoute } from '../components/RestrictedRoute';
import { Role } from '../enums';
import Projects from './Projects';
import Project from './Projects/Project';
import ProjectCreate from './Projects/ProjektCreate';
import ProjectTabs from './Projects/ProjectTabs';
import ProjectTitle from './Projects/ProjectTitle';
import Users from './Users';
import Subsidiaries from './Subsidiaries';
import Catalogs from './Catalogs';
import ItemBoilerplates from './ItemBoilerplates';
import ItemBoilerplate from './ItemBoilerplates/ItemBoilerplate';
import ItemBoilerplateGroups from './ItemBoilerplateGroups';
import ItemBoilerplateTitle from './ItemBoilerplates/itemBoilerplateTitle';
import BillOfQuantityPaper from './BillOfQuantity/BillOfQuantityPaper';
import BillOfQuantities from './BillOfQuantity/BillOfQuantities';
import Missions from './Missions';
import Measurements from './Measurements';
import ProjectBoundaries from './ProjectBoundaries';
import { BillTable } from '../components/Bill/Table';
import CloneDialog from './Projects/CloneDialog';
import MachineTable from '../components/Machine/MachineTable/MachineTable';
import MachineEdit from '../components/Machine/MachineEdit';
import { MachineCreate } from '../components/Machine/MachineCreate';
import CreateEmployeePage from './Employees/CreateEmployeePage';
import Employees from './Employees';
import EditEmployeePage from './Employees/EditEmployeePage';
import { Materials } from './Materials';
import { CreateVehiclePage, VehiclesTable } from './Vehicles';
import { UpdateVehiclePage } from './Vehicles/UpdateVehiclePage';
import OfferConditionsTable, {
  CreateOfferConditionPage,
  UpdateOfferConditionPage,
} from './OfferConditions';
import StandingDataTabs, { StandingDataTitle } from './StandingData';
import AccountingLogTabs, {
  AccountingLogger,
  AccountingLogJournal,
  AccountingLogManual,
  AccountingLogOverview,
  AccountingLogTitle,
} from './AccountingLog';
import EmployeeCalendar from './EmployeeCalendar';
import AbacusInterfaceTitle from '../components/AbacusInterface/AbacusInterfaceTitle';
import AbacusInterfaceTabs from '../components/AbacusInterface/AbacusInterfaceTabs';
import AddressImportStatus from './AbacusInterface/AddressImportStatus';
import { BillOfQuantityEntityType } from '../types/graphql';
import { AddressTable } from './Addresses/AddressTable';
import { PlanningMainView } from './Planning/PlanningMainView';
import { AusmassNeu } from './AusmassNeu/AusmassNeu';
import { BillsNew } from './BillsNew/BillsNew';

const Title: React.FunctionComponent = () => (
  <Switch>
    <Route path="/projekte/erstellen" render={() => <>Projekt erstellen</>} />
    <Route path="/projekte/:projectNumber" component={ProjectTitle} />
    <Route path="/projekte" render={() => <>Projekte</>} />
    <Route path="/auftraege/:id" render={() => <>Auftrag</>} />
    <Route path="/auftraege" render={() => <>Auftrag</>} />
    <Route path="/offerten/:id" render={() => <>Offerte</>} />
    <Route path="/offerten" render={() => <>Offerte</>} />
    <Route path="/einsaetze" render={() => <>Einsätze</>} />
    <Route path="/einsaetzeneu" render={() => <>Einsätze Neu</>} />
    <Route path="/ausmass" render={() => <>Ausmass</>} />
    <Route path="/ausmassneu" render={() => <>Ausmass</>} />
    <Route path="/maschinen" render={() => <>Maschinen</>} />
    <Route path="/mitarbeiter" render={() => <>Mitarbeiter</>} />
    <Route path="/fahrzeuge" render={() => <>Fahrzeuge</>} />
    <Route path="/rechnungen" render={() => <>Rechnungen</>} />
    <Route path="/abgrenzung-projekte" render={() => <>Abgrenzung Projekte</>} />
    <Route path="/kataloge" render={() => <>Kataloge</>} />
    <Route path="/materialien" render={() => <>Materialien</>} />
    <Route path="/vorlagen-gruppen" render={() => <>Vorlagen-Gruppe</>} />
    <Route path="/einzel-positions-vorlagen/:id" component={ItemBoilerplateTitle} />
    <Route path="/einzel-positions-vorlagen" render={() => <>Einzel-Positions-Vorlagen</>} />
    <Route path="/benutzer" component={StandingDataTitle} />
    <Route path="/filialen" component={StandingDataTitle} />
    <Route path="/angebotsbedingungen" component={StandingDataTitle} />
    <Route path="/buchungsjournal/rapport/:sub" component={AccountingLogTitle} />
    <Route path="/buchungsjournal" component={AccountingLogTitle} />
    <Route path="/adressen-import" component={AbacusInterfaceTitle} />
    <Route path="/adressen" render={() => <>Adressen</>} />
    <Route path="/planung" render={() => <>Planung</>} />
  </Switch>
);

const Tabs: React.FunctionComponent = () => (
  <Switch>
    <Route path="/projekte/erstellen" component={undefined} />
    <Route path="/projekte/:id" component={ProjectTabs} />
    <Route path="/angebotsbedingungen" component={StandingDataTabs} />
    <Route path="/filialen" component={StandingDataTabs} />
    <Route path="/benutzer" component={StandingDataTabs} />
    <Route path="/buchungsjournal/rapport/ubersicht" component={AccountingLogTabs} />
    <Route path="/buchungsjournal/rapport/erstellen" component={AccountingLogTabs} />
    <Route path="/buchungsjournal/rapport/:id" component={AccountingLogTabs} />
    <Route path="/buchungsjournal/manuell" component={AccountingLogTabs} />
    <Route path="/buchungsjournal/kalender" component={AccountingLogTabs} />
    <Route path="/buchungsjournal" component={AccountingLogTabs} />
    <Route path="/adressen-import" component={AbacusInterfaceTabs} />
  </Switch>
);

const Main: React.FunctionComponent = () => (
  <Switch>
    <Route path="/projekte/erstellen" component={ProjectCreate} />
    <Route path="/projekte/:projectNumber/clone" component={CloneDialog} />
    <Route path="/projekte/:projectNumber" component={Project} />
    <Route path="/projekte" component={Projects} />
    <Route path="/maschinen/:machineInventoryNumber/editieren" component={MachineEdit} />
    <Route path="/maschinen/erstellen" component={MachineCreate} />
    <Route path="/maschinen" component={MachineTable} />
    <Route path="/auftraege">
      <BillOfQuantities entityType={BillOfQuantityEntityType.ORDER} />
    </Route>
    <Route path="/offerten">
      <BillOfQuantities entityType={BillOfQuantityEntityType.OFFER} />
    </Route>
    <Route path="/einsaetze" component={Missions} />
    <Route path="/ausmass" component={Measurements} />
    <Route path="/ausmassneu" component={AusmassNeu} />
    <Route path="/rechnungen" component={BillTable} />
    <Route path="/rechnungenneu" component={BillsNew} />
    <Route path="/abgrenzung-projekte" component={ProjectBoundaries} />
    <Route path="/kataloge" component={Catalogs} />
    <Route path="/materialien" component={Materials} />
    <Route path="/einzel-positions-vorlagen/:id" component={ItemBoilerplate} />
    <Route path="/einzel-positions-vorlagen" component={ItemBoilerplates} />
    <Route
      exact
      path={['/vorlagen-gruppen', '/vorlagen-gruppen/create']}
      component={ItemBoilerplateGroups}
    />
    <Route path="/auftraege/:id" component={BillOfQuantityPaper} />
    <Route path="/offerten/:id" component={BillOfQuantityPaper} />

    <Route path="/mitarbeiter/:id/editieren" component={EditEmployeePage} />
    <Route path="/mitarbeiter/erstellen" component={CreateEmployeePage} />
    <Route path="/mitarbeiter" component={Employees} />

    <Route path="/fahrzeuge/:id/editieren" component={UpdateVehiclePage} />
    <Route path="/fahrzeuge/erstellen" component={CreateVehiclePage} />
    <Route path="/fahrzeuge" component={VehiclesTable} />

    <Route path="/buchungsjournal/rapport/ubersicht" component={AccountingLogOverview} />
    <Route path="/buchungsjournal/rapport/erstellen" component={AccountingLogger} />
    <Route path="/buchungsjournal/rapport/:id" component={AccountingLogger} />
    <RestrictedRoute
      path="/buchungsjournal/manuell"
      role={Role.ADMIN}
      component={AccountingLogManual}
    />
    <Route path="/buchungsjournal/kalender" component={EmployeeCalendar} />

    <Route path="/buchungsjournal" component={AccountingLogJournal} />

    <RestrictedRoute path="/benutzer" role={Role.SUPER_ADMIN} component={Users} />
    <RestrictedRoute path="/filialen" role={Role.SUPER_ADMIN} component={Subsidiaries} />
    <RestrictedRoute
      path="/angebotsbedingungen/:id/editieren"
      role={Role.SUPER_ADMIN}
      component={UpdateOfferConditionPage}
    />
    <RestrictedRoute
      path="/angebotsbedingungen/erstellen"
      role={Role.SUPER_ADMIN}
      component={CreateOfferConditionPage}
    />
    <RestrictedRoute
      path="/angebotsbedingungen"
      role={Role.SUPER_ADMIN}
      component={OfferConditionsTable}
    />
    <RestrictedRoute
      path="/adressen-import"
      role={Role.SUPER_ADMIN}
      component={AddressImportStatus}
    />
    <Route path="/adressen" component={AddressTable} />
    <RestrictedRoute path="/planung" role={Role.SUPER_ADMIN} component={PlanningMainView} />
    <Redirect to="/projekte" />
  </Switch>
);

export const Pages: React.FunctionComponent = () => {
  const history = useHistory();

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.type === 'link') {
        history.push(event.data.target);
      }
    });
  }, [history]);

  return <Layout main={<Main />} title={<Title />} tabs={<Tabs />} />;
};

export { default as Login } from './Login';
